<template>
  <div id="pet-view">


    <pets-table v-if="showPetTable === true" v-bind:showPetTable="showPetTable"
      v-on:addPet="addPet" v-on:petEdit="petEdit"></pets-table>
    <create-edit v-if="showPetTable === false" v-on:closePetEdit="closePetEdit"
      v-bind:petData="petData" v-bind:edit="edit"></create-edit>


  </div>
</template>

<script>
    import PetsTable from '@/views/site/pets/PetsTable'
    import CreateEdit from '@/views/site/pets/CreateEdit'

    export default {
        name: "Petsview",

        components: {
            PetsTable,
            CreateEdit,

        },
        data() {
            return {
                edit: false,
                petData: [],
                showPetTable: true,
                filter: null,
            };
        },
        methods: {
            addPet() {
                this.showPetTable = false;
                this.edit = false;
                this.petData = [];
            },
            petEdit(pet) {
                this.edit = true;
                this.showPetTable = false;
                this.petData = pet;
            },

            closePetEdit() {
                this.showPetTable = true;
            }
        }
    };
</script>